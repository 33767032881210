<template>
  <div class="m-game-table">
    <div
      v-if="GameList.length && gameData.Items.List.length"
      class="playTypeBar"
      :style="headerColor"
      @click="onToggleAllCollapseClick"
    >
      <div
        class="playTypeBtn"
        :class="{ hasMoreWagerTypes: hasMoreWagerTypes }"
        @click="!isFavoriteMode && openWagerTypePopup()"
      >
        <img
          v-if="gameData.CatID"
          class="icon"
          :class="{ champions: gameData.CatID === 86 }"
          :src="getMenuIconByCatID(gameData.CatID)"
        />
        <template v-if="isFavoriteMode">
          {{ gameData.CatName }}
        </template>
        <template v-else>
          {{ gameNameWithWagerType }}
        </template>
        <span v-if="hasMoreWagerTypes" style="padding-left: 12px">▼</span>
      </div>
      <div v-if="isShowMenu" class="playTypeBtn sub" @click="openWagerTypePopup(true)">
        <span>
          {{ boldFilterType ? $t('Common.FullGame') : $t('common.halfGame') }}
        </span>
      </div>
      <img
        class="arrow"
        :class="activeCollapse.length > 0 ? 'active' : ''"
        src="@/assets/img/mobile/btn_arrow_w.svg"
      />
    </div>
    <template v-if="isShowTemplate">
      <mBoldTable
        v-for="source in leaguesData"
        :key="leagueDataToUniqueKey(source.LeagueID, source.Nu)"
        :source="source"
        :bestHead="gameData.Items.BestHead"
        :isExpanded="isExpanded(leagueDataToUniqueKey(source.LeagueID, source.Nu), source)"
        @toggleCollapse="toggleCollapse(leagueDataToUniqueKey(source.LeagueID, source.Nu))"
      />
    </template>
    <template v-else>
      <div class="table-area">
        <div class="left-area">
          <mGameInfo
            v-for="source in leaguesData"
            :key="leagueDataToUniqueKey(source.LeagueID, source.Nu)"
            :source="source"
            :isExpanded="isExpanded(leagueDataToUniqueKey(source.LeagueID, source.Nu), source)"
            :hasMoreGame="hasMoreGame"
            :BestHead="gameData.Items.BestHead"
            @toggleCollapse="toggleCollapse(leagueDataToUniqueKey(source.LeagueID, source.Nu))"
          />
        </div>
        <div
          class="right-area"
          @mousedown.stop="beginMoving($event)"
          @mouseup.stop="endMoving($event)"
          @touchstart.stop="beginMoving($event)"
          @touchend.stop="endMoving($event)"
        >
          <mGameBetting
            v-for="source in leaguesData"
            :key="leagueDataToUniqueKey(source.LeagueID, source.Nu)"
            :source="source"
            :bestHead="gameData.Items.BestHead"
            :isExpanded="isExpanded(leagueDataToUniqueKey(source.LeagueID, source.Nu), source)"
            :hasMoreGame="hasMoreGame"
            :tabs="tabs"
            :activeTab="activeTab"
            :activeColumns="activeColumns"
            @toggleCollapse="toggleCollapse(leagueDataToUniqueKey(source.LeagueID, source.Nu))"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import mGameInfo from './mGameInfo.vue';
  import mGameBetting from './mGameBetting.vue';
  import mBoldTable from './mBoldTable.vue';
  import { GAME_FAVORITE_CAT, GAME_HOT_CAT } from '@/config/index.js';

  export default {
    components: {
      mGameInfo,
      mGameBetting,
      mBoldTable,
    },
    props: {
      gameData: {
        type: Object,
        default() {
          return {};
        },
      },
      hasMoreGame: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    data() {
      return {
        activeCollapse: [],
        dotStatusArr: [],
        tabColumns: 2,
        activeTab: 1,
        movingX: 0,
        isMoving: false,
      };
    },
    computed: {
      tabs() {
        const { BestHeadWithFilterLimit, tabColumns } = this;
        const { length } = BestHeadWithFilterLimit;
        return Math.ceil(length / tabColumns);
      },
      activeColumns() {
        const { BestHeadWithFilterLimit, activeTab, tabColumns } = this;
        const { length } = BestHeadWithFilterLimit;
        const start = (activeTab - 1) * tabColumns;
        const columns = [];
        for (let i = 0; i < tabColumns; i++) {
          const index = i + start;
          if (index < length) {
            columns.push(index);
          }
        }
        return columns;
      },
      BestHeadWithFilterLimit() {
        if (this.isShowMenu) {
          if (this.boldFilterType) {
            return this.gameData.Items.BestHead[1];
          } else {
            return this.gameData.Items.BestHead[2];
          }
        } else {
          return this.gameData.Items.BestHead[0];
        }
      },
      gameStore() {
        return this.$store.state.Game;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      leaguesData() {
        return this.gameData.Items.List.map((league) => {
          if (this.isCornerGame) {
            const newData = league.Team.map((teamData) => {
              const newTeamData = JSON.parse(JSON.stringify(teamData));
              if (this.boldFilterType) {
                newTeamData.Wager = teamData.Wager;
              } else {
                newTeamData.Wager = teamData.Wager2;
              }
              return newTeamData;
            });
            return { ...league, Team: newData };
          } else {
            return league;
          }
        }).filter((league) => league.Team.length);
      },
      // 賽事排序方式 0: 熱門 1: 時間
      tableSort() {
        return this.$store.state.Setting.UserSetting.tableSort;
      },
      GameList() {
        return this.$store.getters['Game/GameListFilterBySelectLeague'];
      },
      CatMapData() {
        return this.$store.state.Game.CatMapData;
      },
      currentCatData() {
        const { selectCatID } = this.gameStore;
        const currentCatData = this.$store.getters['Game/selectMenuCatList'].find(
          (it) => it.catid === selectCatID
        );
        return currentCatData;
      },
      currentWagerType() {
        if (this.currentCatData) {
          const { selectWagerTypeKey } = this.gameStore;
          const currentWagerType = this.currentCatData.Items.find(
            (it) => it.WagerTypeKey === selectWagerTypeKey
          );
          return currentWagerType;
        }
        return null;
      },
      isFavoriteMode() {
        const { selectCatID, selectWagerTypeKey } = this;
        return (
          selectCatID === GAME_FAVORITE_CAT ||
          (selectCatID === GAME_HOT_CAT && selectWagerTypeKey === GAME_HOT_CAT)
        );
      },
      gameNameWithWagerType() {
        const gameName = this.gameData.CatName;
        const wagerTypeName = this.currentWagerType
          ? ' - ' + this.currentWagerType.WagerTypeName
          : '';
        return gameName + wagerTypeName;
      },
      headerColor() {
        const color = this.CatMapData[this.gameData.Items.List[0].CatID]?.color || '#7d9364';
        return {
          'background-color': color,
        };
      },
      boldFilterType() {
        return this.$store.state.Game.boldFilterType;
      },
      hasMoreWagerTypes() {
        return this.currentCatData?.Items.length > 1;
      },
      isCornerGame() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 2:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isBoldGame() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 3:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowMenu() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 2:
              case 4:
                return true;
              default:
                return false;
            }
          case 102:
            switch (this.selectWagerTypeKey) {
              case 6:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowTemplate() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 3:
              case 4:
              case 5:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
    },
    methods: {
      beginMoving(event) {
        if (event.touches) {
          event = event.touches[0];
        }
        this.isMoving = true;
        this.movingX = event.screenX;
      },
      endMoving(event) {
        if (event.changedTouches) {
          event = event.changedTouches[0];
        }
        this.isMoving = false;
        const { screenX } = event;
        const deltaX = screenX - this.movingX;
        if (deltaX < -20) {
          if (this.activeTab < this.tabs) {
            this.activeTab += 1;
          }
        } else if (deltaX > 20) {
          if (this.activeTab > 1) {
            this.activeTab -= 1;
          }
        }
      },
      onToggleAllCollapseClick(e) {
        if (e.target !== e.currentTarget) return;
        this.toggleAllCollapse();
      },
      isExpanded(uKey, source) {
        // if (source && source.CatID === 31) {
        //  return true;
        // }
        return this.activeCollapse.includes(uKey);
      },
      toggleCollapse(uKey) {
        if (this.activeCollapse.includes(uKey)) {
          this.activeCollapse = this.activeCollapse.filter((it) => it !== uKey);
        } else {
          this.activeCollapse.push(uKey);
        }
      },
      toggleAllCollapse() {
        this.activeCollapse = this.activeCollapse.length > 0 ? [] : this.expandAllCollapse();
      },
      expandAllCollapse() {
        // 展開全部
        return (this.activeCollapse = this.gameData.Items.List.map((it) =>
          this.leagueDataToUniqueKey(it.LeagueID, it.Nu)
        ));
      },
      openWagerTypePopup(isSub = false) {
        if (this.currentCatData.Items.length > 1 && !this.isFavoriteMode) {
          this.$emit('openWagerTypePopup', isSub);
        }
      },
      getMenuIconByCatID(catId) {
        if (catId === 86) {
          return require('@/assets/img/champion.png');
        }
        const icon = this.CatMapData[catId].icon;
        return require('@/assets/img/common/menuIcon/' + icon);
      },
      leagueDataToUniqueKey(LeagueID, Nu) {
        return this.$lib.leagueDataToUniqueKey(LeagueID, Nu);
      },
    },
    mounted() {
      this.expandAllCollapse();
    },
  };
</script>

<style lang="scss" scoped>
  .m-game-table {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .table-area {
      display: flex;
    }

    .left-area {
      position: relative;
      width: 50%;
      z-index: 2;
      transition: width 500ms ease;
    }

    .right-area {
      width: 50%;
      position: relative;
      z-index: 1;
      //overflow-x: auto;
      //overflow-y: auto;
      //overscroll-behavior: none;
    }

    .playTypeBar {
      display: flex;
      align-items: center;
      font-size: 0.8em;
      line-height: 1em;
      min-height: 30px;
      color: #fff;
      padding: 3px 6px;
      background-color: #7d9364;
      position: sticky;
      width: 100%;
      z-index: 10;
      top: 0;
      left: 0;

      .playTypeBtn {
        display: flex;
        align-items: center;
        position: relative;
        align-self: stretch;

        padding: 3px 10px 3px 30px;
        margin-right: 6px;
        min-height: 22px;
        line-height: 1;
        font-size: 1.2rem;

        &.hasMoreWagerTypes {
          border: 1px solid #fff;
          border-radius: 45px;
          background-color: rgba(255, 255, 255, 0.15);
          &:active {
            background-color: rgba(0, 0, 0, 0.15);
          }
        }

        img.icon {
          position: absolute;
          left: 5px;
          top: 50%;
          height: 18px;
          width: 18px;
          transform: translateY(-50%);
          filter: grayscale(1) brightness(3);

          &.champions {
            filter: none;
          }
        }

        &.sub {
          padding: 3px 10px 3px 10px;
          min-width: 50px;
          border: 1px solid #fff;
          border-radius: 45px;
          background-color: rgba(255, 255, 255, 0.15);
        }
      }

      img.arrow {
        width: 1.1rem;
        margin-left: auto;
        margin-right: 6px;
        transition: 200ms ease;
        &.active {
          transform: rotate(-90deg);
        }
      }
    }
  }
</style>
