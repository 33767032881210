<template>
  <div id="m-setting-items">
    <div class="setting-item">
      <div class="item-header" @click="typeActive = !typeActive">
        <img class="item-icon" src="@/assets/img/mobile/odds-type.svg" />
        <div class="item-name">{{ $t('Common.OddType') }}</div>
        <div class="item-value">{{
          model.mIncludePrincipal
            ? $t('GamesSetup.IncludePrincipal')
            : $t('GamesSetup.NotIncludePrincipal')
        }}</div>
      </div>
      <transition name="slide">
        <div v-if="typeActive" class="item-content">
          <label class="item-label">
            <input v-model="model.mIncludePrincipal" type="radio" :value="true" />
            <div>{{ $t('GamesSetup.IncludePrincipal') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mIncludePrincipal" type="radio" :value="false" />
            <div>{{ $t('GamesSetup.NotIncludePrincipal') }}</div>
          </label>
        </div>
      </transition>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="sortActive = !sortActive">
        <img class="item-icon" src="@/assets/img/mobile/event-sort.svg" />
        <div class="item-name">{{ $t('Common.GameSort') }}</div>
        <div class="item-value">{{
          model.mTableSort === 0 ? $t('Common.Hot') : $t('Common.Time')
        }}</div>
      </div>
      <transition name="slide">
        <div v-if="sortActive" class="item-content">
          <label class="item-label">
            <input v-model="model.mTableSort" type="radio" :value="0" />
            <div>{{ $t('Common.Hot') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mTableSort" type="radio" :value="1" />
            <div>{{ $t('Common.Time') }}</div>
          </label>
        </div>
      </transition>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="confirmActive = !confirmActive">
        <img class="item-icon" src="@/assets/img/mobile/bet-confirm.svg" />
        <div class="item-name">{{ $t('GamesSettingDialog.BetConfirmMessage') }}</div>
        <div class="item-value">{{
          model.mShowBetConfirm ? $t('Common.Visible') : $t('Common.InVisible')
        }}</div>
      </div>
      <transition name="slide">
        <div v-if="confirmActive" class="item-content">
          <label class="item-label">
            <input v-model="model.mShowBetConfirm" type="radio" :value="true" />
            <div>{{ $t('Common.Visible') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mShowBetConfirm" type="radio" :value="false" />
            <div>{{ $t('Common.InVisible') }}</div>
          </label>
        </div>
      </transition>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="amountActive = !amountActive">
        <img class="item-icon" src="@/assets/img/mobile/default-amount.svg" />
        <div class="item-name">{{ $t('GamesSettingDialog.DefaultMount') }}</div>
        <div class="item-value">{{
          model.mDefaultAmountType === 2
            ? model.mDefaultAmountValue
            : model.mDefaultAmountType === 0
            ? $t('Common.Close')
            : $t('Common.LastBet')
        }}</div>
      </div>
      <transition name="slide">
        <div v-if="amountActive" class="item-content">
          <label class="item-label">
            <input v-model="model.mDefaultAmountType" type="radio" :value="0" />
            <div>{{ $t('Common.Close') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mDefaultAmountType" type="radio" :value="1" />
            <div>{{ $t('Common.LastBet') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mDefaultAmountType" type="radio" :value="2" />
            <div>{{ $t('Common.CustomMoney') }}</div>
          </label>
          <input
            v-show="model.mDefaultAmountType == 2"
            v-model="model.mDefaultAmountValue"
            type="number"
            class="amount-input"
          />
        </div>
      </transition>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="strayAmountActive = !strayAmountActive">
        <img class="item-icon" src="@/assets/img/mobile/default-parlay-amount.svg" />
        <div class="item-name">{{ $t('GamesSettingDialog.DefaultStrayBet') }}</div>
        <div class="item-value">{{
          model.mDefaultStrayAmountType === 2
            ? model.mDefaultStrayAmountValue
            : model.mDefaultStrayAmountType === 0
            ? $t('Common.Close')
            : $t('Common.LastBet')
        }}</div>
      </div>
      <div v-if="strayAmountActive" class="item-content">
        <label class="item-label">
          <input v-model="model.mDefaultStrayAmountType" type="radio" :value="0" />
          <div>{{ $t('Common.Close') }}</div>
        </label>
        <label class="item-label">
          <input v-model="model.mDefaultStrayAmountType" type="radio" :value="1" />
          <div>{{ $t('Common.LastBet') }}</div>
        </label>
        <label class="item-label">
          <input v-model="model.mDefaultStrayAmountType" type="radio" :value="2" />
          <div>{{ $t('Common.CustomMoney') }}</div>
        </label>
        <input
          v-show="model.mDefaultStrayAmountType == 2"
          v-model="model.mDefaultStrayAmountValue"
          class="amount-input"
          type="number"
        />
      </div>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="betActive = !betActive">
        <img class="item-icon" src="@/assets/img/mobile/bet-switch.svg" />
        <div class="item-name">{{ $t('GamesSettingDialog.BetOver2Items', ['']) }}</div>
        <div class="item-value">{{
          model.mAutoSwitchToStrayMode
            ? $t('GamesSettingDialog.ShowStrayBetView')
            : $t('GamesSettingDialog.ShowSingleBetView')
        }}</div>
      </div>
      <transition name="slide">
        <div v-if="betActive" class="item-content">
          <label class="item-label">
            <input v-model="model.mAutoSwitchToStrayMode" type="radio" :value="false" />
            <div>{{ $t('GamesSettingDialog.ShowSingleBetView') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mAutoSwitchToStrayMode" type="radio" :value="true" />
            <div>{{ $t('GamesSettingDialog.ShowStrayBetView') }}</div>
          </label>
        </div>
      </transition>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="autoBestActive = !autoBestActive">
        <img class="item-icon" src="@/assets/img/mobile/auto-best-odds.svg" />
        <div class="item-name">{{ $t('GamesSetup.AcceptBetter') }}</div>
        <div class="item-value">{{ model.mAcceptBetter ? $t('Common.Yes') : $t('Common.No') }}</div>
      </div>
      <transition name="slide">
        <div v-if="autoBestActive" class="item-content">
          <label class="item-label">
            <input v-model="model.mAcceptBetter" type="radio" :value="true" />
            <div>{{ $t('Common.Yes') }}</div>
          </label>
          <label class="item-label">
            <input v-model="model.mAcceptBetter" type="radio" :value="false" />
            <div>{{ $t('Common.No') }}</div>
          </label>
        </div>
      </transition>
    </div>
    <div class="setting-item">
      <div class="item-header" @click="chipsActive = !chipsActive">
        <img class="item-icon" src="@/assets/img/mobile/custom-amount.svg" />
        <div class="item-name">{{ $t('Common.CustomBet') }}</div>
        <div v-if="chipsActive" class="item-tip"
          >{{ $t('Common.MostSet') }} {{ maxChips }} {{ $t('Common.Indivual') }}</div
        >
      </div>
      <transition name="slide">
        <div v-if="chipsActive" class="item-content">
          <div class="item-chips">
            <div
              v-for="chip in chipsData"
              :key="chip.value"
              :class="['item-chip', { active: activeChips.includes(chip.value) }]"
              @click="chipClickHandler(chip.value)"
              >{{ toCurrency(chip.value) }}</div
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { toCurrency } from '@/config/balance';

  export default {
    data() {
      return {
        chipsData: this.$SportLib.chipsData,
        maxChips: 4,
        model: {
          mAcceptBetter: null,
          mIncludePrincipal: null,
          mTableSort: null,
          mShowBetConfirm: false,
          mAutoSwitchToStrayMode: false,
          mDefaultAmountType: 0,
          mDefaultAmountValue: 0,
          mDefaultStrayAmountType: 0,
          mDefaultStrayAmountValue: 0,
        },
        typeActive: false,
        sortActive: false,
        confirmActive: false,
        amountActive: false,
        strayAmountActive: false,
        betActive: false,
        autoBestActive: false,
        chipsActive: false,
        activeChips: [],
      };
    },
    computed: {
      settings() {
        return this.$store.state.Setting.UserSetting;
      },
    },
    watch: {
      model: {
        handler() {
          this.saveSettings();
        },
        deep: true,
      },
      'model.mTableSort': {
        handler() {
          this.$emit('updateGameDetail');
        },
      },
      activeChips() {
        this.saveSettings();
      },
    },
    methods: {
      toCurrency,
      loadSettings() {
        this.model.mAcceptBetter = this.settings.acceptBetter;
        this.model.mIncludePrincipal = this.settings.includePrincipal;
        this.model.mTableSort = this.settings.tableSort;
        this.model.mShowBetConfirm = this.settings.showBetConfirm;
        this.model.mAutoSwitchToStrayMode = this.settings.autoSwitchToStrayMode;
        this.model.mDefaultAmountType = this.settings.defaultAmount.type;
        this.model.mDefaultAmountValue = this.settings.defaultAmount.amount;
        this.model.mDefaultStrayAmountType = this.settings.defaultStrayAmount.type;
        this.model.mDefaultStrayAmountValue = this.settings.defaultStrayAmount.amount;
        this.activeChips = Array.from(new Set(this.settings.preferChips))
          .sort((a, b) => a - b)
          .slice(0, this.maxChips);
      },
      saveSettings() {
        const { model, $store } = this;
        const { UserSetting } = $store.state.Setting;
        const newSettings = {
          acceptBetter: this.model.mAcceptBetter,
          includePrincipal: this.model.mIncludePrincipal,
          tableSort: this.model.mTableSort,
          showBetConfirm: this.model.mShowBetConfirm,
          autoSwitchToStrayMode: this.model.mAutoSwitchToStrayMode,
          defaultAmount: {
            type: Number(model.mDefaultAmountType),
            amount:
              model.mDefaultAmountType == 2
                ? model.mDefaultAmountValue
                : UserSetting.defaultAmount.amount,
          },
          defaultStrayAmount: {
            type: Number(model.mDefaultStrayAmountType),
            amount:
              model.mDefaultStrayAmountType == 2
                ? model.mDefaultStrayAmountValue
                : UserSetting.defaultStrayAmount.amount,
          },
          preferChips: this.chipsData
            .map((it) => it.value)
            .filter((v) => this.activeChips.includes(v)),
        };
        this.$store.dispatch('Setting/setSettings', newSettings);
      },
      chipClickHandler(value) {
        if (this.activeChips.includes(value)) {
          if (this.activeChips.length > 1) {
            this.activeChips = this.activeChips.filter((it) => it !== value);
          }
        } else {
          if (this.activeChips.length < this.maxChips) {
            this.activeChips.push(value);
          }
        }
      },
    },
    mounted() {
      this.loadSettings();
    },
  };
</script>

<style lang="scss">
  #m-setting-items {
    > .setting-item {
      margin-bottom: 1px;

      > .item-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 32px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #ccc;
        user-select: none;
        cursor: pointer;

        > .item-icon {
          width: 20px;
          height: 20px;
          transition: transform 800ms ease;
        }

        > .item-name {
          margin-left: 6px;
          color: #333;
          font-size: 14px;
          white-space: nowrap;
        }

        > .item-value {
          margin-left: auto;
          color: #0169ff;
          font-size: 13px;
          white-space: nowrap;
        }

        > .item-tip {
          margin-left: 4px;
          color: red;
          font-size: 13px;
          white-space: nowrap;
        }

        &:hover,
        &:active {
          > .item-icon {
            transform: scale(1.2);
          }

          > .item-name {
            color: #000;
          }
        }
      }

      > .item-content {
        > .item-label {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          height: 24px;
          margin: 4px 16px 4px 32px;
          font-size: 14px;
          white-space: nowrap;
          cursor: pointer;

          > input[type='radio'] {
            margin: 0 4px 0 0;
            font-size: 14px;
            cursor: pointer;
          }
        }

        > .amount-input {
          margin: 0px 16px 4px 48px;
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #0169ff;
          background-color: #ffd5d5;
          text-align: center;
        }

        > .item-chips {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4px;
          margin: 4px 8px 8px 32px;

          > .item-chip {
            padding: 4px 8px;
            border: 2px solid transparent;
            border-radius: 4px;
            background-color: #fff;
            font-size: 13px;
            text-align: right;
            cursor: pointer;

            &:hover,
            &:active {
              border: 2px solid #0169ff;
            }

            &.active {
              background: #ffd5d5;
              border: 2px solid #0169ff;
            }
          }
        }

        &.slide-enter-active {
          transition: all 800ms ease;
        }

        &.slide-leave-active {
          transition: all 800ms ease;
        }

        &.slide-enter-to,
        &.slide-leave {
          max-height: 200px;
          overflow: hidden;
        }

        &.slide-enter,
        &.slide-leave-to {
          max-height: 0;
          overflow: hidden;
        }
      }
    }
  }
</style>
